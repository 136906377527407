import React, { useState } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ProductAddModal = ({show,setShow}) => {
    

    const handleClose = () => {setShow(false)};
  return (
    
    <React.Fragment> 
        <Modal show={show} onHide={handleClose}>
        
    <Modal.Header closeButton>
   
    </Modal.Header>
    <Modal.Body>
        <div className='addProductTitle'>
        Your inquiry has been submitted successfully !!
        </div>
    </Modal.Body>

  </Modal>
      
    </React.Fragment>
  )
}

export default ProductAddModal