import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const ComanPage = () => {
  const location = useLocation();
  const [content, setContent] = useState([]);
  const [title, setTitle] = useState("")
  
  useEffect(()=>{
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1]
    console.log("lplplplplplp",currentPath)
    setTitle(currentPath);
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getCMS/cmss/${currentPath}`)
    .then((res)=>
      setContent(res.data)
    )
    .catch((err)=>
      console.log(err)
    )
  },[location])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={title} midTitle='About us' bTitle={title}/>
      <Container className="galleryRow ">
      <div className="sub-title text-animation">
                <h6>
                  {title}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={50}
                    height={6}
                    viewBox="0 0 50 6"
                  >
                    <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
                  </svg>
                </h6>
             
              </div>
      {content.length > 0 &&<div
          dangerouslySetInnerHTML={{ __html: content[0].Description }}>
        </div>}
      </Container>
    </React.Fragment>
  );
};

export default ComanPage;
