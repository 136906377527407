import React, { useContext } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Logo from "../assets/Img/logo-new.png";
import { LiaEdit } from "react-icons/lia";
import { Link, useNavigate } from "react-router-dom";
import { ProductContext } from "../Context/ProductContext";

const InquiryModal = ({datas}) => {
  const [validQuant, setValidQuant] = useState(false);
  const {handleSet} = useContext(ProductContext)
  const navig = useNavigate();
  const [show, setShow] = useState(false);
  const [quantity, setQuantity] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    console.log(datas)
    setShow(true);
  }

  const handleAddToCart=()=>{
    if(Number(quantity) > 0){
      setValidQuant(false);
    let preData = localStorage.getItem("cartItem");
    let stat = false;
    if (preData) {
      preData = JSON.parse(preData);
      preData = preData.map((it)=>{
        if(it.item._id == datas._id)
          {
             let quant = Number(it.quantity) + Number(quantity)
             let newData = {
                   item : datas,
                   quantity : quant
             }
             stat = true;
             return newData;
          }
          else
          {
            return it;
          }
      })
      if(stat == false){
        preData = [...preData,{
          item : datas,
          quantity : quantity
        }]
      }
      
      handleSet(preData)
      localStorage.setItem('cartItem', JSON.stringify(preData));
    navig("/inquiry")
    }
    else
    {
      localStorage.setItem('cartItem', JSON.stringify([{
        item : datas,
        quantity
      }]));
      handleSet([{
        item : datas,
        quantity
      }])
    }
    navig("/inquiry")
  }
  else{
    setValidQuant(true);
  }
  }
  return (
    <React.Fragment>
      <Link
        onClick={handleShow}
        htmlFor="colour-attribute-1"
        className="filter-attribute-label product-btn"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        <LiaEdit className="bi bi-pencil-square" /> Inquiry
      </Link>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <img src={Logo} className="modal-logo" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            <h6 className="mrb-2">Product Name</h6>
            <p className="mrb-15">{datas.productName}</p>
            <h6 className="mrb-2">Product Details</h6>
            <p className="mrb-15">
            {datas.specifications && Object.entries(datas.specifications).map(([key, value]) => (
          <li key={key}>
            {key} - {value}
          </li>
        ))}
            </p>
            <h6 className="mrb-2">Quantity</h6>
            {/* <form> */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-inner">
                    <input type="number" placeholder="Enter Quantity" onChange={(e)=>setQuantity(e.target.value)}/>
                    <br /><br />
                        {validQuant && <p style={{color:"red"}}>Please enter valid quantity.</p>}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-inner text-center" style={{cursor:"pointer"}}>
                    <a
                      // href="inquiry-cart.html"
                      className="primary-btn3 btn-hover"
                      // type="submit"
                      onClick={handleAddToCart}
                    >
                      Inquiry
                      <svg
                        width={12}
                        height={12}
                        viewBox="0 0 12 12"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z"></path>
                      </svg>
                      <span style={{ top: "56.3906px", left: "-5.54688px" }} />
                    </a>
                  </div>
                </div>
              </div>
            {/* </form> */}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default InquiryModal;
