import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const Career = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/allmanagecareers`
        )
        .then((res) => {
          console.log('career data',res.data);
          setData(res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={"Career"} bTitle={"Career"}/>
      <Container className="galleryRow">
        <div className="sub-title text-animation">
          <h6>
            Careers
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={50}
              height={6}
              viewBox="0 0 50 6"
            >
              <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
            </svg>
          </h6>
        </div>
        <p>
          As a market-leading tools and equipments business, we offer
          unparalleled opportunities for talented individuals in search of a
          rewarding career. Whether you are a graduate seeking your first job or
          a more experienced professional interested in career advancement, you
          can learn more about us and what a career with Griphold entails.
        </p>
        <h5 className="mt-50">View our current vacancies:</h5>
        {data &&
          data.map((item) => (
            <React.Fragment key={item._id}>
              <div className="mb-90">
                <div className="opningDiv">{item.Title}</div>
                <h6>Description</h6>
                <div className="qualificationDiv">
                  <p dangerouslySetInnerHTML={{ __html: item.Description }} />
                </div>
                <div className="positionDiv">
                  <div className="positionTitle">
                    <span>No. Of Position :</span>
                    {item.Position}
                  </div>
                  <div className="">
                    <Link
                      className="applayLink"
                      to={`mailto:career@gripholdexports.com?subject=${item.Title}`}
                    >
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
      </Container>
    </React.Fragment>
  );
};

export default Career;
