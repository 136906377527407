import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import NewProductImg from "../assets/Img/product-5.jpg"
import axios from 'axios'
import no_img from "../assets/Img/no-image.jpg";
import Breadcrumb from '../Component/BradeCrum';
import { useLocation } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import HeaderMobile from '../Component/MobileHader';


const ImageWithFallback = ({ src }) => {
    const [imgSrc, setImgSrc] = useState(src);
    
    useEffect(() => {
      setImgSrc(src);
    }, [src]);
  
    const handleError = () => {
      setImgSrc(no_img);
    };
  
    return (
      <img 
        src={imgSrc} 
        alt='img' 
        onError={handleError}
        className='whats-new-modal-img'
      />
    );
  };

const WhatsNew = () => {
   const [data,setData]=useState([])
   const location = useLocation();
    const [title, setTitle] = useState("")
   const [show, setShow] = useState(false);
   const [imageUrl, setImageUrl] = useState("");
  
  useEffect(()=>{
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1]
    console.log("lplplplplplp",currentPath)
    setTitle(currentPath);
  },[])
    useEffect(()=>{
        const fetchData = () => {
            axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/whatsnewdata`)
            .then((res)=>{
                console.log(res.data)
                setData(res.data)
            })
            .catch((error)=>{
                console.error(error)
            })
        }
        fetchData()
    window.scrollTo(0, 0);
    },[])

    const handleClose = () =>{
      setImageUrl("")
      setShow(false);
    }
    const handleShow = (WhatsNewImage) => {
      setImageUrl(WhatsNewImage)
      setShow(true);
    }

  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={title}  bTitle={title}/>
        <Container>
            <Row className='galleryRow'>
            <div className="sub-title text-animation">
                <h6>
                What's New
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={50}
                    height={6}
                    viewBox="0 0 50 6"
                  >
                    <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
                  </svg>
                </h6>
             
              </div>
                {data?.map((d)=>
                <Col lg={4} className='mb-3' key={d._id}>
                <Card className='newProductImgCard mb-5 galleryCard' onClick={()=>handleShow(d.WhatsNewImage)}>
                 {/* <img src={`$${process.env.REACT_APP_GRIPHOLD_API_URL}/${d.WhatsNewImage}`} alt='img' /> */}
                 <ImageWithFallback 
          src={d.WhatsNewImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${d.WhatsNewImage}` : no_img} 
        />
                 <div className='newProductTitle'>
                 {d.Title}
                 </div>
                </Card>
                </Col>
                )}
            </Row>
            <Modal show={show} centered onHide={handleClose}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>

                    {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.GalleryImage}`} alt="img" /> */}

                    {/* <img src={data.GalleryImage} alt="img" /> */}
                    <ImageWithFallback 
          src={ `${process.env.REACT_APP_GRIPHOLD_API_URL}/${imageUrl}` } 
        />

                  </Modal.Body>
                </Modal>
        </Container>
    </React.Fragment>
  )
}

export default WhatsNew