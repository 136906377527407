import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CategoryImg from "../assets/Img/industrial-hands-tools/1-2-industrial-sockets.png"
import { Link } from 'react-router-dom';
import no_img from "../assets/Img/no-image.jpg";

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(()=>{
    console.log("okokokokokokokoko",imgSrc)
  },[src])

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};

const Category = ({Title,subsubCategoryData,bUrl}) => {
    const categoryData = [
        {
            img:CategoryImg,
            title:"Ring Type Straight Slugging Wrenches",
            description:"Text will coming soon...Text will coming soon...Text will coming soon..."
        },
        {
            img:CategoryImg,
            title:"Open Type Straight Slugging Wrenches",
            description:"Text will coming soon...Text will coming soon...Text will coming soon..."
        },
        {
            img:CategoryImg,
            title:"Heavy Duty Insert Sockets",
            description:"Text will coming soon...Text will coming soon...Text will coming soon..."
        }

    ]
  return (
    <React.Fragment>
        <Row>
        <Col lg={12} >
          <h5>
     
            {Title}
            </h5>
        </Col>
        {subsubCategoryData?.map((data)=>
        <Col xl={4} lg={6} sm={6} className="mt-20">
          <div className="product-card">
            <div className="product-card-img">
              <Link to={`${bUrl}${data._id}`}>
                {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.productImage}`} alt='img' /> */}
                <ImageWithFallback 
          src={data.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.productImage}` : no_img} 
        />
              </Link>
            </div>
            <div className="product-card-content">
              <h6 className="product-category-title"><Link to={`${bUrl}${data._id}`}>{data.productSubSubCategory}</Link></h6>
              <div dangerouslySetInnerHTML={{ __html: data.description }} />
            </div>
          </div>
        </Col>
          )}
        </Row>
    </React.Fragment>
  )
}

export default Category