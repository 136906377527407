import React, { useContext, useEffect, useState } from 'react'
import Breadcrumb from '../Component/BradeCrum'
import { Col, Container, Row, Form } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { RiDeleteBinFill } from "react-icons/ri";
import axios from 'axios';
import ProductAddModal from '../Component/ProductAddModal';
import { ProductContext } from '../Context/ProductContext';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import HeaderMobile from '../Component/MobileHader';

const inquirySchema = Yup.object().shape({
  MobileNo: Yup.string()
    .min(10, 'Too Short!')
    .required('Required'),
  Email: Yup.string().email('Invalid email').required('Required'),
  ContactPerson: Yup.string().required('Required'),
  CompanyName: Yup.string().required('Required'),
  Designation: Yup.string().required('Required'),
  WebsiteURL: Yup.string().required('Required'),
  Country: Yup.string().required('Required'),
  City: Yup.string().required('Required'),
  State: Yup.string().required('Required'),
  Address: Yup.string().required('Required'),
  Message: Yup.string().required('Required'),
});


const Inquiry = () => {
  const {item,handleSet} = useContext(ProductContext)
  const navig = useNavigate();
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      CompanyName : "",
      Designation : "",
      ContactPerson : "",
      MobileNo : "",
      Email : "",
      WebsiteURL : "",
      Country : "",
      State : "",
      City : "",
      Address : "",
      Message : ""
    },
    validationSchema: inquirySchema,
    onSubmit: (values, { setSubmitting }) => {
      handleSubmit(values);
      setSubmitting(false);
    }
  });
  const[user, setUser] = useState({
    CompanyName : "",
    Designation : "",
    ContactPerson : "",
    MobileNo : "",
    Email : "",
    WebsiteURL : "",
    Country : "",
    State : "",
    City : "",
    Address : "",
    Message : ""
  })

  const handleChange=(e)=>{
    setUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }

  useEffect(()=>{
    handleAddToCart();
  },[])

  const handleAddToCart=()=>{
    let preData = item;
    if (preData) {
      console.log("preData",preData)
      setItems(preData)
    }
  }

  const handleSubmit=(values)=>{
    axios.post(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/create/Inquiry`,{items,user:values})
    .then((res)=>{
      handleShow();
      localStorage.removeItem('cartItem');
      setUser({
        CompanyName : "",
        Designation : "",
        ContactPerson : "",
        MobileNo : "",
        Email : "",
        WebsiteURL : "",
        Country : "",
        State : "",
        City : "",
        Address : "",
        Message : ""
      })
      handleSet([]);
      setTimeout(()=>{
        navig("/")
      },3000)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  const handleShow = () => setShow(true);

  const handleDelete=(index)=>{
    let updatedItem = items.filter((it,i)=>
      i != index
    )
    handleSet(updatedItem)
    localStorage.setItem('cartItem', JSON.stringify(updatedItem));
    setItems(updatedItem);
  }

  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb bTitle={"Inquiry"}/>
  <div className="product-table">
  <Container className="container">
    {items.length > 0 ? <Row >
      <Col lg={12} className=" col-12">
        <h1>Inquiry</h1>
      </Col>
      <Col lg={12} className="col-12  pb-60">
        <table className="rwd-table">
          <tbody>
            <tr>
              <th>Sr.No</th>
              <th>Product Name</th>
              <th>Product Details</th>
              <th>Quantity</th>
              <th>Action</th>
            </tr>
            {items.map((it,index)=>
            <tr>
            <td data-th="Supplier Code">
              1
            </td>
            <td data-th="Supplier Name">
              {it.item.productName}
            </td>
            <td data-th="Supplier Name">
            {Object.entries(it.item.specifications).map(([key, value]) => (
          <li key={key}>
            {key} - {value}
          </li>
        ))}
            </td>
            <td data-th="Due Date">
              {it.quantity}
            </td>
            <td data-th="Net Amount">
              {/* Delete */}
              <Link to="#">
                <RiDeleteBinFill  className="delete-icon" onClick={()=>handleDelete(index)}/>
              </Link>
            </td>
          </tr>
            )}
            
          </tbody>
        </table>
        <div className="contact-form-wrap mt-25">
          {/* <form> */}
            <Row >
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Company Name*</label>
                  {/* <input type="text" placeholder="Company Name" onChange={handleChange} name='CompanyName' value={user.CompanyName}/> */}
                  <Form.Control
                    type="text"
                    name="CompanyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.CompanyName}
                    isInvalid={!!formik.errors.CompanyName && formik.touched.CompanyName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.CompanyName}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Designation*</label>
                  {/* <input type="text" placeholder="Designation" onChange={handleChange} name='Designation' value={user.Designation}/> */}
                  <Form.Control
                    type="text"
                    name="Designation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Designation}
                    isInvalid={!!formik.errors.Designation && formik.touched.Designation}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Designation}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Contact Person*</label>
                  {/* <input type="text" placeholder="Contact Person" onChange={handleChange} name='ContactPerson' value={user.ContactPerson}/> */}
                  <Form.Control
                    type="text"
                    name="ContactPerson"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ContactPerson}
                    isInvalid={!!formik.errors.ContactPerson && formik.touched.ContactPerson}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.ContactPerson}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col lg={4} md={12} >
                <div className="form-inner mb-30">
                  <label>Mobile No.*</label>
                  {/* <input type="text" placeholder="Mobile No." onChange={handleChange} name='MobileNo' value={user.MobileNo}/> */}
                  <Form.Control
                    type="text"
                    name="MobileNo"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.MobileNo}
                    isInvalid={!!formik.errors.MobileNo && formik.touched.MobileNo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.MobileNo}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Email*</label>
                  {/* <input type="text" placeholder="Email" onChange={handleChange} name='Email' value={user.Email}/> */}
                  <Form.Control
                    type="email"
                    name="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Email}
                    isInvalid={!!formik.errors.Email && formik.touched.Email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Email}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Website URL*</label>
                  {/* <input type="text" placeholder="Website URL" onChange={handleChange} name='WebsiteURL' value={user.WebsiteURL}/> */}
                  <Form.Control
                    type="text"
                    name="WebsiteURL"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.WebsiteURL}
                    isInvalid={!!formik.errors.WebsiteURL && formik.touched.WebsiteURL}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.WebsiteURL}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>Country*</label>
                  {/* <input type="text" placeholder="Country" onChange={handleChange} name='Country' value={user.Country}/> */}
                  <Form.Control
                    type="text"
                    name="Country"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Country}
                    isInvalid={!!formik.errors.Country && formik.touched.Country}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Country}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>State*</label>
                  {/* <input type="text" placeholder="State" onChange={handleChange} name='State' value={user.State}/> */}
                  <Form.Control
                    type="text"
                    name="State"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.State}
                    isInvalid={!!formik.errors.State && formik.touched.State}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.State}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={4} >
                <div className="form-inner mb-30">
                  <label>City*</label>
                  {/* <input type="text" placeholder="City" onChange={handleChange} name='City' value={user.City}/> */}
                  <Form.Control
                    type="text"
                    name="City"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.City}
                    isInvalid={!!formik.errors.City && formik.touched.City}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.City}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={6} >
                <div className="form-inner mb-30">
                  <label>Address*</label>
                  {/* <textarea type="text" placeholder="Address" defaultValue={""} onChange={handleChange} name='Address' value={user.Address}/> */}
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={3}
                    name="Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Address}
                    isInvalid={!!formik.errors.Address && formik.touched.Address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Address}
                  </Form.Control.Feedback>
                </div>
              </Col>
              <Col md={12} lg={6}  >
                <div className="form-inner mb-30">
                  <label>Message*</label>
                  {/* <textarea type="text" placeholder="Message" defaultValue={""} onChange={handleChange} name='Message' value={user.Message}/> */}
                  <Form.Control
                    type="text"
                    as="textarea"
                    rows={3}
                    name="Message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.Message}
                    isInvalid={!!formik.errors.Message && formik.touched.Message}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.Message}
                  </Form.Control.Feedback>
                </div>
              </Col>
            </Row>
            <div className="form-inner text-center">
              <a className="primary-btn3 btn-hover" type="button" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={formik.handleSubmit}>
                Add Inquiry
                <svg width={12} height={12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z">
                  </path>
                </svg>
                <span />
              </a>
            </div>
          {/* </form> */}
        </div>
      </Col>
    </Row> : 
    <h4 style={{textAlign:"center"}}>Your Cart is Empty !</h4>
    }
    <ProductAddModal show={show} setShow={setShow}/>
  </Container>
</div>

    </React.Fragment>
  )
}

export default Inquiry