import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const BranchOffice = () => {
    const location = useLocation();
    const [title, setTitle] = useState("")
  
  useEffect(()=>{
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1]
    console.log("lplplplplplp",currentPath)
    setTitle(currentPath);
  },[])
//   const [content, setContent] = useState([]);

//   useEffect(()=>{
//     axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getCMS/cmss/${title}`)
//     .then((res)=>
//       setContent(res.data)
//     )
//     .catch((err)=>
//       console.log(err)
//     )
//   },[title])
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={title} bTitle={title}/>
      <Container className="galleryRow ">
      {/* {content.length > 0 &&<div
          dangerouslySetInnerHTML={{ __html: content[0].Description }}>
        </div>} */}
        <div>
            <h3>Coming Soon...</h3>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default BranchOffice;
