import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import no_img from "../assets/Img/no-image.jpg";
import { BsFilePdfFill } from "react-icons/bs";
import DownloadModal from "../Modal/downlodModal";
import HeaderMobile from "../Component/MobileHader";




const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};

const Download = () => {
    const location = useLocation();
    const [title, setTitle] = useState("")
  
  useEffect(()=>{
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1]
    console.log("lplplplplplp",currentPath)
    setTitle(currentPath);
  },[])
  const [content, setContent] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/alldownload`)
    .then((res)=>
      setContent(res.data)
    )
    .catch((err)=>
      console.log(err)
    )
  },[title])


  const handleDownload = async (uurl) => {
    const url = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${uurl}`;
    let dnFileName = uurl.split("/");
    dnFileName = dnFileName[dnFileName.length-1];

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${dnFileName}.pdf`;
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };
  
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={title}  bTitle={title}/>
      <Container className="galleryRow ">
      {/* {content.length > 0 &&<div
          dangerouslySetInnerHTML={{ __html: content[0].Description }}>
        </div>} */}
        <Tabs>
                        <Tab eventKey="download">
                              <table className="rwd-table">
                                <tbody>
                                  <tr>
                                    <th>Sr.No</th>
                                    {/* <th>Credential Image</th> */}
                                    {/*<th>Product Group Name</th>*/}
                                    <th style={{textAlign:"center"}}>Title</th>
                                    <th style={{textAlign:"center"}}>Download</th>
                                  </tr>
                                  {content.length > 0 && content.map((item,index)=>
                                    <tr key={index}>
                                    <td data-th="Supplier Code">{index+1}</td>
                                    {/* <td data-th="Supplier Name" className="spec-img">
                                      <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${productData.productImage}`} alt="Product" />
                                      <ImageWithFallback 
                                            src={no_img} 
                                       />
                                    </td> */}
                                    {/*<td data-th="Invoice Number">
                                      Slugging wrenches & Insert Sockets
                                    </td>*/}
                                    <td data-th="Invoice Date" style={{textAlign:"center"}}>
                                      {item.name}
                                    </td>
                                    <td data-th="Due Date" style={{textAlign:"center"}}>
                                      <div className="download-catalogue-btn">
                                        {/* <Link to="#" onClick={()=>handleDownload(item.file)}> */}
                                          {/* <BsFilePdfFill className="bi-file-pdf" /> */}
                                          <span><DownloadModal file={item.file}/></span>
                                        {/* </Link> */}
                                      </div>
                                    </td>
                                  </tr>
                                  )}
                                  
                                </tbody>
                              </table>
                            </Tab>
                            </Tabs>
      </Container>
    </React.Fragment>
  );
};

export default Download;
