import axios from 'axios';
import React, { createContext, useState } from 'react';

export const BCNContext=createContext();

const BCNContextProvider=({children})=>{
    const [bcnHeader,setBcnHeader] = useState("");

    return(
    <BCNContext.Provider value={{setBcnHeader,bcnHeader}}>{children}</BCNContext.Provider>)
}
export default BCNContextProvider;