import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, Container, Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const loginSchema = Yup.object().shape({
    contact: Yup.string()
      .min(10, 'Too Short!')
      
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
    fullName: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
  });
const Register = () => {
    const formik = useFormik({
        initialValues: { email: '', contact: '',fullName:"",address:"" },
        validationSchema: loginSchema,
        onSubmit: (values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }
      });
  return (
    <React.Fragment>
        <Container >
        <div className='loginMainDiv'>
        <Card className='loginCard' >
          <Card.Body>
            <h1 className="text-center">Register</h1>
            <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Row} controlId="formFullName" className='mt-3'>
                <Form.Label column sm={4}>
                  Full Name:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="fullName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.fullName}
                    isInvalid={!!formik.errors.fullName && formik.touched.fullName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEmail" className='mt-3'>
                <Form.Label column sm={4}>
                  Email:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email && formik.touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formEmail" className='mt-3'>
                <Form.Label column sm={4}>
                  Address:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    isInvalid={!!formik.errors.address && formik.touched.address}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPassword" className="mt-3">
                <Form.Label column sm={4}>
                  Contact Number:
                </Form.Label>
                <Col sm={8}>
                  <Form.Control
                    type="text"
                    name="contact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contact}
                    isInvalid={!!formik.errors.contact && formik.touched.contact}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.contact}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <div className='registerLink'>Already Have An Account?<span><Link to="/login">Login</Link></span> </div> 
              <Form.Group as={Row} className="mt-4">
                <Col className="text-center">
                  <button className='loginBtn' type="submit" >
                  Login
                  </button>
                </Col>
              </Form.Group>
            
            </Form>
          </Card.Body>
        </Card>
        </div>
      </Container>
    </React.Fragment>
  )
}

export default Register