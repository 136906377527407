import React from 'react'
import DiscirbeLogo from "../assets/Img/distributor-logo.png"
import { Link } from 'react-router-dom'

const Distributer = () => {
  return (
    <React.Fragment>
      <div className="partner-area pt-130 mb-130">
  <div className="container-lg container-fluid">
    <div className="row">
      <div className="col-lg-12">
        <div className="partner-wrap">
          <div className="title1">
            <h6>Our Trusted Distributors</h6>
          </div>
          <div className="marquee light">
            <div className="marquee__group">
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
            </div>
            <div aria-hidden="true" className="marquee__group">
            <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
            </div>
          </div>
          <div className="marquee dark">
            <div className="marquee__group">
            <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
            </div>
            <div aria-hidden="true" className="marquee__group">
            <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
              <Link to="#"><img src={DiscirbeLogo} /></Link>
            </div>
          </div>
          <div className="title2">
            <h6>Almost 20+ Distributors we have</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </React.Fragment>
  )
}

export default Distributer