import React, { useContext, useEffect, useState } from "react";
import Breadcrumb from "../Component/BradeCrum";
import { Link, useParams } from "react-router-dom";
import { BsFilePdfFill } from "react-icons/bs";
import { Col, Row } from "react-bootstrap";
import Category from "../Component/Category";
import InquiryModal from "../Component/InquiryModal";
import axios from "axios";
import no_img from "../assets/Img/no-image.jpg";
import { BCNContext } from "../Context/BreadcrumName";
import HeaderMobile from "../Component/MobileHader";


const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};

const ProductSubCategory = () => {
  const [subsubCategoryData, setSubSubCategoryData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [originalProductData, setOriginalProductData] = useState([]);
  const { subsubCatId } = useParams();
  const [minValue, setMinValue] = useState({});
  const [maxValue, setMaxValue] = useState({});
  const [minRangeValue, setMinRangeValue] = useState({});
  const [maxRangeValue, setMaxRangeValue] = useState({});
  const [specifications, setSpecifications] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [showCategory, setShowCategory] = useState(true);
  const [showDescription, setShowDescription] = useState(true);
  const [heading, setHeading] = useState("")
  const [visibleFilters, setVisibleFilters] = useState({});
  const {bcnHeader} = useContext(BCNContext);

  const toggleVisibility = (key) => {
    setVisibleFilters(prevState => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };
  const handleDownload = async (p) => {
    let uurl = p.subCategoryDetails.productPDF;
    const url = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${uurl}`;
    let dnFileName = uurl.split("/");
    dnFileName = dnFileName[dnFileName.length-1];

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${dnFileName}.pdf`; // specify filename here
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [subSubCategoryRes, filterRes] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/products-by-sub-sub-cat-id/${subsubCatId}`
          ),
          axios.get(
            `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/filter/${subsubCatId}`
          ),
        ]);

        const firstItem = subSubCategoryRes.data.data[0];
        setSubSubCategoryData(subSubCategoryRes.data.data);
        setOriginalProductData(subSubCategoryRes.data.data);

        const productRes = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/products-by-sub-cat-id/${firstItem.subCategoryID}`
        );
        const products = productRes.data.data;
        const categoryRes = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/all-sub-sub-category-byId/${subsubCatId}`
        );
        setHeading(categoryRes.data.data.productSubSubCategory)
        setProductData(products);
        // setOriginalProductData(products);

        const filterData = filterRes.data.data;

        const specs = products.flatMap((product) =>
          Object.entries(product.specifications || {}).map(([key, value]) => ({
            key,
            value,
          }))
        );

        const uniqueSpecs = Array.from(
          new Set(specs.map((spec) => spec.key))
        ).map((key) => {
          const spec = specs.find((spec) => spec.key === key);
          const filterType = filterData.find(
            (filter) => filter.FilterType[key]
          );
          return {
            key: spec.key,
            value: spec.value,
            filterType: filterType
              ? filterType.FilterType[spec.key]
              : "unknown",
          };
        });
console.log("uniqueSpecs",uniqueSpecs)
        setSpecifications(uniqueSpecs);

        uniqueSpecs.forEach((spec) => {
          if (spec.filterType === "range") {
            const specValues = products
              .map(
                (p) =>
                  p.specifications &&
                  parseInt(p.specifications[spec.key], 10)
              )
              .filter(Boolean);
            if (specValues.length) {
              const min = Math.min(...specValues);
              const max = Math.max(...specValues);
              setRangeValue(spec.key, min, max);
            }
          }
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };


    fetchData();
  }, [subsubCatId]);

  useEffect(() => {
    applyFilters();
  }, [selectedFilters, minValue, maxValue]);
  const setRangeValue = (key, min, max) => {
    setMinValue((prev) => ({ ...prev, [key]: min }));
    setMaxValue((prev) => ({ ...prev, [key]: max }));
    setMinRangeValue((prev) => ({ ...prev, [key]: min }));
    setMaxRangeValue((prev) => ({ ...prev, [key]: max }));
  };


  const applyFilters = () => {
    let filteredProducts = [...originalProductData];
    Object.entries(selectedFilters).forEach(([key, value]) => {
      if (value !== "") {
        filteredProducts = filteredProducts.filter(
          (product) =>
            product.specifications && product.specifications[key] === value
        );
      }
    });

    filteredProducts = filteredProducts.filter((product) => {
      return Object.keys(minValue).every(
        (key) =>
          (product.specifications &&
            parseInt(product.specifications[key])) >= minValue[key] &&
          (product.specifications &&
            parseInt(product.specifications[key])) <= maxValue[key]
      );
    });
console.log("filteredProducts",filteredProducts)
    setSubSubCategoryData(filteredProducts);
    if (
      Object.keys(selectedFilters).length === 0 &&
      Object.keys(minValue).every((key) => minValue[key] === 0) &&
      Object.keys(maxValue).every(
        (key) => maxValue[key] === maxRangeValue[key]
      )
    ) {
      setShowDescription(true);
      setShowCategory(true);
    } else {
      setShowDescription(false);
      setShowCategory(false);
    }
    
  };

  const handleFilterChange = (key, value) => {
    setSelectedFilters({ ...selectedFilters, [key]: value });
  };

  const removeFilter = (key) => {
    const { [key]: removedFilter, ...restFilters } = selectedFilters;
    setSelectedFilters(restFilters);
  };

  const renderFilterComponent = (spec) => {
    if (spec.filterType === "range") {
      const minVal = minRangeValue[spec.key] || 0;
      const maxVal = maxRangeValue[spec.key] || 500;
  
      const minPercentage = ((minValue[spec.key] || minVal) - minVal) / (maxVal - minVal) * 100;
      const maxPercentage = ((maxValue[spec.key] || maxVal) - minVal) / (maxVal - minVal) * 100;
  
      return (
        <div className="filter-item second-part mt-15" key={spec.key}>
          <p className="filter-item-inner-heading">
            {spec.key}
            <span
              onClick={() => toggleVisibility(spec.key)}
              style={{ cursor: "pointer", marginLeft: "10px" }}
            >
              {visibleFilters[spec.key] ? "▲" : "▼"}
            </span>
          </p>
          {visibleFilters[spec.key] && (
            <div className="d-flex">
              <div className="price-wrapper">
                <div className="price-input">
                  <div className="field">
                    <span>Min</span>
                    <input
                      type="number"
                      className="input-min w-50"
                      value={minValue[spec.key] || 0}
                      onChange={(e) => handleMinInputChange(e, spec.key)}
                      disabled
                    />
                  </div>
                  <div className="separator">-</div>
                  <div className="field">
                    <span>Max</span>
                    <input
                      type="number"
                      className="input-max w-50"
                      value={maxValue[spec.key] || 0}
                      onChange={(e) => handleMaxInputChange(e, spec.key)}
                      disabled
                    />
                  </div>
                </div>
                <div className="slider">
                  <div className="progress"></div>
                </div>
                <div className="range-input">
                  <input
                    type="range"
                    className="range-min"
                    min={minVal}
                    max={maxVal}
                    value={minValue[spec.key] || minVal}
                    onChange={(e) => handleMinRangeChange(e, spec.key)}
                    style={{ background: `linear-gradient(to right, white 0%, white ${minPercentage}%, #01458e ${minPercentage}%, #01458e ${maxPercentage}%, white ${maxPercentage}%, white 100%)` }}
                  />
                  <input
                    type="range"
                    className="range-max"
                    min={minVal}
                    max={maxVal}
                    value={maxValue[spec.key] || maxVal}
                    onChange={(e) => handleMaxRangeChange(e, spec.key)}
                    style={{ background: `linear-gradient(to right, white 0%, white ${minPercentage}%, #01458e ${minPercentage}%, #01458e ${maxPercentage}%, white ${maxPercentage}%, white 100%)` }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (spec.filterType === "normal") {
      const values = originalProductData
        .map((product) =>
          product.specifications ? product.specifications[spec.key] : null
        )
        .filter((value, index, self) => value && self.indexOf(value) === index);
  
      return (
        <div className="filter-item third-part mt-15" key={spec.key}>
          <section className="filter-item-inner">
            <p className="filter-item-inner-heading">{spec.key}</p>
            <div className="filter-attribute-list-inner numb-ser">
              <ul className="filter-attribute-list ul-reset">
                <li className="filter-attribute-item">
                  <label className="filter-attribute-label ib-m">
                    <input
                      type="radio"
                      className="d-none"
                      name={spec.key}
                      value=""
                      onChange={() => handleFilterChange(spec.key, "")}
                      checked={selectedFilters[spec.key] === ""}
                    />
                    All
                  </label>
                </li>
                {values.map((value, index) => (
                  <li key={index} className="filter-attribute-item">
                    <label className="filter-attribute-label ib-m">
                      <input
                        type="radio"
                        className="d-none"
                        name={spec.key}
                        value={value}
                        onChange={() => handleFilterChange(spec.key, value)}
                        checked={selectedFilters[spec.key] === value}
                      />
                      {value}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </div>
      );
    }
    return null;
  };
  const handleMinInputChange = (e, key) => {
    const value = parseInt(e.target.value) || 0;
    setMinValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleMaxInputChange = (e, key) => {
    const value = parseInt(e.target.value) || 0;
    setMaxValue((prev) => ({ ...prev, [key]: value }));
  };

  const handleMinRangeChange = (e, key) => {
    const value = parseInt(e.target.value, 10) || 0;
    if (value <= maxValue[key]) {
      setMinValue((prev) => ({ ...prev, [key]: value }));
    }
  };
  const handleMaxRangeChange = (e, key) => {
    const value = parseInt(e.target.value) || 0;
    if (value >= minValue[key]) {
      setMaxValue((prev) => ({ ...prev, [key]: value }));
    }
  };
  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={heading} midTitle={bcnHeader} bTitle={heading}/>
      <div className="container-fluid">
        <Row>
          <Col xl={4} lg={4} className="order-xl-1 order-1">
            <div className="shop-sidebar">
              <main className="main" role="main">
                <div className="wrapper cf">
                  <div className="sidebar">
                    <h1 className="sidebar-heading">Filter Products</h1>
                    {Object.keys(selectedFilters).length > 0 && (
                      <div className="col-lg-12 mt-4">
                        <h4>Applied Filters:</h4>
                        <ul>
                          {Object.entries(selectedFilters).map(
                            ([key, value]) => (
                              <li key={key}>
                                {`${key}: ${value}`}
                                <button
                                  className="btn btn-link btn-sm"
                                  onClick={() => removeFilter(key)}
                                >
                                  Remove
                                </button>
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    )}
                    <ul className="filter ul-reset">
                      {specifications.map((spec) =>
                        renderFilterComponent(spec)
                      )}
                    </ul>
                  </div>
                </div>
              </main>
            </div>
          </Col>
          <Col xl={8} lg={8} className="order-xl-2 order-2">
            <Row className="gy-5 mb-70">

              <div className="col-lg-12">
                <h4>All Products</h4>
              </div>
              {subsubCategoryData?.map((p) => (
                <Col xl={4} lg={6} sm={6} className="mt-10" key={p._id}>
                  <div className="product-card">
                    <div className="product-card-img">
                      <Link to={`/productDetails/${p._id}`}>
                        {/* <img
                          src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${p.productImage}`}
                          alt={p.productName}
                        /> */}
                        <ImageWithFallback 
          src={p.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${p.productImage}` : no_img} 
        />
                      </Link>
                    </div>
                    <div className="product-card-content">
                      <h6>

                        <Link to={`/productDetails/${p._id}`}>

                          <span>{p.productName}</span>  {p.subTitle}
                        </Link>
                      </h6>
                      <Link
                        htmlFor="colour-attribute-1"
                        className="filter-attribute-label product-btn"
                        to="#"
                        onClick={()=>handleDownload(p)}
                      >
                        <BsFilePdfFill className="bi-file-pdf-fill icon-pdf" />{" "}
                        Catalogue
                      </Link>
                      <InquiryModal datas={p}/>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ProductSubCategory;
