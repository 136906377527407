import React, { useState } from 'react';
import About2 from "../assets/Img/about-2.jpg";
import About1 from "../assets/Img/about-1.jpg";
import { Link as RouterLink } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import CountUp from 'react-countup';
import { useSpring, animated } from '@react-spring/web';
import VisibilitySensor from 'react-visibility-sensor';
import { FaArrowRight } from "react-icons/fa6";

const CompanyDetails = () => {
  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1500 },
  });

  const descriptionProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 500,
    config: { duration: 1500 },
  });

  const [viewed, setViewed] = useState({
    branch: false,
    distributor: false,
    dealer: false,
  });

  const onVisibilityChange = (isVisible, key) => {
    if (isVisible && !viewed[key]) {
      setViewed((prevViewed) => ({ ...prevViewed, [key]: true }));
    } else if (!isVisible && viewed[key]) {
      setViewed((prevViewed) => ({ ...prevViewed, [key]: false }));
    }
  };

  return (
    <React.Fragment>
      <div className="home1-about-section mb-130 mob-mt-30">
        <div className="container-lg container-fluid">
          <Row className="g-4 mb-60">
            <Col lg={4} md={4}>
              <div className="sub-title two text-animation">
                <div className="section-title text-animation">
                  <animated.h2 style={titleProps}>
                  <h2>
                    Who <span>We Are</span>
                  </h2>
                  </animated.h2>
                </div>
              </div>
            </Col>
            <Col lg={8} md={8}>
              <div className="section-title two text-animation">
              <animated.h2 style={titleProps}>
                <h2>GRIPHOLD ENGINEERING PVT. LTD.</h2>
                </animated.h2>
              </div>
             
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={6} className="d-lg-flex justify-content-end align-items-start">
              <div className="about-left animet-images magnetic-item pt-30">
                <img src={About2} alt='img' />
              </div>
            </Col>
            <Col lg={4} md={6}>
              <div className="about-right-img animet-images magnetic-item mob-mt-30">
                <img src={About1} alt='img' />
              </div>
            </Col>
            <Col lg={4} md={12} className="md-mt-30 mob-mt-30">
              <div className="about-content text-animation">
                <animated.p style={descriptionProps}>
                <p>Intelligent Tooling with a Global Outlook, Griphold Engineering Pvt. Ltd. is a world-class provider of an expansive array of bolting solutions. Our broad range of torque tools caters to the equipment needs of a gamut of sectors. With its stronghold on quality and technology the company has earned a sound reputation and loyal customer base globally.</p>
                <p>As a market-leading bolting solutions company, we have the depth and breadth of expertise to respond to the most technically challenging and time-sensitive projects.</p>
                </animated.p>
                <RouterLink to="/About-Griphold" className='aboutMore'>
                  About More
                  <FaArrowRight/>
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                    <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z" />
                  </svg> */}
                </RouterLink>
              </div>
            </Col>
          </Row>
          <ul className="counter-wrap">
            <li className="single-counter" style={{width:"13%"}}>
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'branch')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.branch && (
                        <CountUp
                          start={0}
                          end={423}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Branch Offices</p>
                </div>
              </VisibilitySensor>
            </li>
            <li className="single-counter">
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'distributor')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.distributor && (
                        <CountUp
                          start={0}
                          end={240}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Distributors</p>
                </div>
              </VisibilitySensor>
            </li>
            <li className="single-counter">
              <VisibilitySensor onChange={(isVisible) => onVisibilityChange(isVisible, 'dealer')} delayedCall>
                <div className="content">
                  <div className="number">
                    <h2 className="counter">
                      {viewed.dealer && (
                        <CountUp
                          start={0}
                          end={365}
                          duration={3}
                        />
                      )}+
                    </h2>
                  </div>
                  <p>Dealers</p>
                </div>
              </VisibilitySensor>
            </li>
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CompanyDetails;
