import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GalleryImg from '../assets/Img/Factory.png';
import axios from 'axios';
import no_img from "../assets/Img/no-image.jpg";
import Breadcrumb from '../Component/BradeCrum';
import HeaderMobile from '../Component/MobileHader';


const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};


const GalleryList = () => {

 const [data,setData]=useState([])

useEffect(()=>{

  const fetchData = () =>{
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/gallery/category`)
    .then((res)=>{
      console.log(res.data)
      setData(res.data)
    })
    .catch((error)=>{
      console.error(error)
    })
  }
  fetchData();
  window.scrollTo(0, 0);
},[])
   
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={"Gallery"} bTitle={"Gallery"}/>
      <Container>
        <Row className='galleryRow'>
        <div className="sub-title text-animation">
                <h6>
                Gallery
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={50}
                    height={6}
                    viewBox="0 0 50 6"
                  >
                    <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
                  </svg>
                </h6>
               
              </div>
              {data && data.map((data)=>
          <Col xl={3} lg={4} md={6} key={data._id} style={{paddingBottom:"25px"}}>
          
            <Link to={`/galleryDetails/${data._id}`}>
              <Card className='galleryCard'>
                {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.GalleryImage}`} alt='img' /> */}
                <ImageWithFallback 
          src={data.GalleryImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.GalleryImage}` : no_img} 
        />
                <div className='galleryTitle'>
                  {data.Title}
                </div>
              </Card>
            </Link>
          </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default GalleryList;
