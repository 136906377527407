import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import HeaderMobile from '../Component/MobileHader'

const Services = () => {
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Container>
            <div className='galleryRow  '>
            <h5 className='serviceTitle'>
            SERVICES
            </h5>
            <p>
            Griphold provides Repairs and Servicing facilities to its customers for the following equipments:
            </p>
            <ol>
                <li>
                Hydraulic, Electric, Pneumatic and Mechanical Torque Wrenches;
                </li>
                <li>
                Hydraulic cylinders / Jacks and pumps
                </li>
                <li>
                Hydraulic Flange spreaders
                </li>
                <li>
                Hydraulic Bolt Tensioning Devices
                </li>
                <li>
                Hydraulic Nut Splitters
                </li>
                <li>
                Hydraulic Pullers
                </li>
                <li>
                Other hydraulic equipments.
                </li>
            </ol>
            <div className='serviceRequestDiv'>
                <Link className='serviceRequestLink' to="#">
                Service Request
                </Link>
            </div>
            </div>
        </Container>
    </React.Fragment>
  )
}

export default Services