import React, { useEffect, useState } from 'react'
import Breadcrumb from '../Component/BradeCrum'
import Product from '../Component/Product'
import HeaderMobile from '../Component/MobileHader'
import axios from 'axios'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import no_img from "../assets/Img/no-image.jpg";

const ImageWithFallback = ({ src }) => {
    const [imgSrc, setImgSrc] = useState(src);
  
    useEffect(()=>{
      console.log("okokokokokokokoko",imgSrc)
    },[])
  
    const handleError = () => {
      setImgSrc(no_img);
    };
  
    return (
      <img 
        src={imgSrc} 
        alt='img' 
        onError={handleError}
      />
    );
  };

const AllProducts = () => {
  const [heading, setHeading] = useState("All Product")
const [productData, setProductData] = useState([])
  useEffect(()=>{
    try {
        axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/getallcategory`).then((res)=>{
            console.log(res)
            setProductData(res.data)
        });
    }
    catch(error){
        console.log(error)
    }
  },[])
  return (
    <React.Fragment>
      <HeaderMobile/>
     <Breadcrumb heading={heading}  bTitle={heading}/>
     <div className="container-fluid">
  <Row className=" g-xl-4 gy-5">
    <Col xxl={12} className=" order-xl-2 order-1">
      <Row className=" gy-5 mb-70 product-page">
        {productData && productData.filter((item)=>item.IsActive===true).map((data)=>
        <Col lg={3} sm={6} className="col-sm-6" key={data._id}>
          <div className="product-card">
            <div className="product-card-img">
              <Link  to={`/productlist/${data._id}`}>
                {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.productImage}`} /> */}
                <ImageWithFallback 
          src={data.categoryImage? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.categoryImage}` : no_img} 
        />
              </Link>
            </div>
            <div className="product-card-content">
              <h6 className="mb-0"><Link  to={`/productlist/${data._id}`}>{data.categoryName}</Link></h6>
            </div>
          </div>
        </Col>
    )}
      </Row>
    </Col>
  </Row>
</div>
    </React.Fragment>
  )
}

export default AllProducts