import React, { useEffect, useState } from 'react'
import Product1 from "../assets/Img/product-1.jpg"
import Product2 from "../assets/Img/product-2.jpg"
import Product3 from "../assets/Img/product-3.jpg"
import Product4 from "../assets/Img/product-4.jpg"
import Product5 from "../assets/Img/product-5.jpg"
import Product6 from "../assets/Img/product-6.jpg"
import Product7 from "../assets/Img/product-7.jpg"
import Product8 from "../assets/Img/product-8.jpg"
import Product9 from "../assets/Img/product-9.jpg"
import Product10 from "../assets/Img/product-10.jpg"
import Product11 from "../assets/Img/product-11.jpg"
import Product12 from "../assets/Img/product-12.jpg"
import no_img from "../assets/Img/no-image.jpg";
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
      className='our-product-img'
    />
  );
};

const ProductListing = () => {
  const [product,setProduct] = useState([])
  const limitedProducts = product.slice(0, 6);
    // const productData =[
    //     {
    //         img:Product1,
    //         title:"Industrial Hand Tools"
    //     },
    //     {
    //         img:Product2,
    //         title:"Mechanical Torquing Tools"
    //     },
    //     {
    //         img:Product3,
    //         title:"Hydraulic Torquing Tools"
    //     },
    //     {
    //         img:Product4,
    //         title:"Pneumatic Tools"
    //     },
    //     {
    //         img:Product5,
    //         title:"Hydraulic Lifting Tools"
    //     },
    //     {
    //         img:Product6,
    //         title:"Hydraulic Bolt Tensioning Tools"
    //     },
    //     {
    //         img:Product7,
    //         title:"Hydraulic Rescue Tools"
    //     },
    //     {
    //         img:Product8,
    //         title:"Hydraulic Nut Breaking Tools"
    //     },
    //     {
    //         img:Product9,
    //         title:"Flange Management Tools"
    //     },
    //     {
    //         img:Product10,
    //         title:"Hydraulic Bearing Pullers"
    //     },
    //     {
    //         img:Product11,
    //         title:"Hydraulic Pumps Accessories"
    //     },
    //     {
    //         img:Product12,
    //         title:"Material Handling Equipments"
    //     }
    // ]
    useEffect(() => {
      const fetchData = async () => {
        try {
          const res = await axios.get(
            `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/Allproducts`
          );
          console.log("data==>", res.data);
          setProduct(res.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
  return (
    <React.Fragment>
   <div className="home2-portfolio-section">
  <div className="container-lg container-fluid">
    <Row className=" mb-60">
      <Col lg={12} className="col-lg-12">
        <div className="section-title text-animation">
          <h2>Our Products</h2>
          <div className="dash-and-paragraph three">
            <div className="dash" />
            <div className="btn-and-paragraph">
              {/* <a href="#">
                Explore More
                <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                  <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z">
                  </path>
                </svg>
              </a> */}
            </div>
          </div>
        </div>
      </Col>
    </Row>
    <Row className=" gy-lg-5 g-4 justify-content-between">
        {limitedProducts?.map((d)=>
      <Col lg={4} md={6} key={d.id}  >
        <div className="portfolio-card magnetic-item">
          <div className="image-and-tag">
            <div className="portfolio-img">
              {/* <img src={d.productImage?`${process.env.REACT_APP_GRIPHOLD_API_URL}/${d.productImage}`:no_img} alt={d.productImage} /> */}
              <ImageWithFallback 
          src={d.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${d.productImage}` : no_img} 
        />
              <Link className="details-btn" to={`/productDetails/${d._id}`}>
                <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                  <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z">
                  </path>
                </svg>
              </Link>
            </div>
          </div>
          <div className="portfolio-content">
            <h4><Link to={`/productDetails/${d._id}`}>{d.productName}</Link></h4>
          </div>
        </div>
      </Col>
      )}
   
    </Row>
  </div>
</div>


    </React.Fragment>
  )
}

export default ProductListing