import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";

const CustomerPage = () => {
  const [content, setContent] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/allcostomer`)
    .then((res)=>{
        let obj = {};
        let getData = res.data;
        for(var a=0; a<getData.length; a++)
            {
                let keyName = getData[a].categoryName
                if(!obj[keyName])
                    {
                        obj[keyName] = [getData[a].name]
                    }
                    else
                    {
                        obj[keyName] = [...obj[keyName],getData[a].name]
                    }
            }
            setContent(obj)
    })
    .catch((err)=>
      console.log(err)
    )
  },[])
  return (
    <React.Fragment>
      <HeaderMobile/>
      <Breadcrumb heading={"Customers"} bTitle={"Customers"}/>
      <Container className="galleryRow ">
        {/* <h1>CUSTOMER'S</h1> */}
        <ul>
        {Object.entries(content).map(([key, value]) => (

<>
<br />
<h3 style={{backgroundColor:"#01458e", color:"white", padding:"5px 5px", fontSize:'15px'}}>{key}</h3>
<Row>
    {value.map((item,index)=>
    <Col lg={4} key={index} style={{ padding:"8px 15px", fontWeight:"bold", fontSize:'15px'}}>{item}</Col>
    )}
</Row>
</>

        ))}
        </ul>
        
      </Container>
    </React.Fragment>
  );
};

export default CustomerPage;
