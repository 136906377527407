import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Breadcrumb from "../Component/BradeCrum";
import no_img from "../assets/Img/no-image.jpg";
import { BsFilePdfFill } from "react-icons/bs";
import HeaderMobile from "../Component/MobileHader";


const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
      style={{height:"180px", objectFit:"contain"}}
    />
  );
};

const Certification = () => {
    const location = useLocation();
    const [title, setTitle] = useState("")
  
  useEffect(()=>{
    let currentPath = location.pathname;
    currentPath = currentPath.split("/")[1]
    console.log("lplplplplplp",currentPath)
    setTitle(currentPath);
  },[])
  const [content, setContent] = useState([]);

  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/allcertification`)
    .then((res)=>
      setContent(res.data)
    )
    .catch((err)=>
      console.log(err)
    )
  },[title])

  const handleView =(imageUrl)=>{
    let imageUurl = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${imageUrl}`
    window.open(imageUurl, '_blank');
  }
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={title}  bTitle={title}/>
      <Container className="galleryRow ">
      <div className="container-fluid">
        <Row>
          <Col xl={12} className="order-xl-2 order-1">
            <Row className="gy-5 mb-70">

              {/* <div className="col-lg-12">
                <h4>All Products</h4>
              </div> */}
              {content?.map((p) => (
                <Col lg={4} sm={6} className="mt-10" key={p._id}>
                  <div className="product-card">
                    <div className="product-card-img">
                      <Link to="#">
                        <ImageWithFallback 
          src={p.image ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${p.image}` : no_img} 
        />
                      </Link>
                    </div>
                    <div className="product-card-content">
                      <h6 style={{textAlign:"center"}}>

                        <Link to="#">

                          <span>{p.name}</span>
                        </Link>
                      </h6>
                      <div style={{display:"flex",justifyContent:"center"}}>
                      <Link
                        htmlFor="colour-attribute-1"
                        className="filter-attribute-label product-btn"
                        to="#"
                        onClick={()=>handleView(p.image)}
                      >
                        View
                      </Link>
                      </div>
                      {/* <InquiryModal datas={p}/> */}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </div>
      </Container>
    </React.Fragment>
  );
};

export default Certification;
