import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import GalleryImg from "../assets/Img/Factory.png";
import axios from "axios";
import no_img from "../assets/Img/no-image.jpg";
import Breadcrumb from "../Component/BradeCrum";
import HeaderMobile from "../Component/MobileHader";


const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};

const GalleryDetails = () => {
  const [show, setShow] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const { _id } = useParams();
  const [title, setTitle] = useState("");


  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/gallery/by-cat-id/${_id}`
        )
        .then((res) => {
          console.log("gallry", res.data);
          const data = res.data;
          console.log(data)
          setTitle(data.Title);
          setGalleryData(data); 
        })
        .catch((error) => {
          console.error(error);
        });
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  // const galleryData =[
  //     {
  //         img:GalleryImg,

  //     },
  //     {
  //         img:GalleryImg,

  //     },
  //     {
  //         img:GalleryImg,

  //     },

  // ]
  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={title} bTitle={"Gallery Detail"}/>
      <Container>
        <Row className="galleryRow">
          <div className="sub-title text-animation">
            <h6>
              Gallery
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={50}
                height={6}
                viewBox="0 0 50 6"
              >
                <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
              </svg>
            </h6>
          </div>
          {galleryData.length>0 &&
            galleryData[0].GalleryImage.map((data) => (
              <Col lg={3} key={data._id}>
                <Modal show={show} centered onHide={handleClose}>
                  <Modal.Header closeButton>
                    {/* <Modal.Title>Modal heading</Modal.Title> */}
                  </Modal.Header>
                  <Modal.Body>

                    {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.GalleryImage}`} alt="img" /> */}

                    {/* <img src={data.GalleryImage} alt="img" /> */}
                    <ImageWithFallback 
          src={ `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data}` } 
        />

                  </Modal.Body>
                </Modal>

                <Link to={`/galleryDetails/${data._id}`}>
                  <Card className="galleryCard" onClick={handleShow}>

                    {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.GalleryImage}`} alt="img" /> */}

                    {/* <img src={data.GalleryImage} alt="img" /> */}
                    <ImageWithFallback 
          src={ `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data}`} 
        />

                  </Card>
                </Link>
              </Col>
            ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default GalleryDetails;
