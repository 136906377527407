import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSpring, animated } from '@react-spring/web';

const Services = () => {
  const [services, setServices] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  const titleProps = useSpring({
    from: { opacity: 0, transform: 'translateY(-20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: { duration: 1000 },
  });

  const descriptionProps = useSpring({
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: 500,
    config: { duration: 1000 },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/allmanageservices`);
        if (!response.ok) {
          throw new Error('Failed to fetch services');
        }
        const data = await response.json();
        setServices(data); // Update state with fetched data
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchData();
    window.scrollTo(0, 0);
  }, []);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="home1-solution-section pb-130">
      <div className="container-lg container-fluid">
        <Row className="g-4 mb-60">
          <Col lg={4} md={4}>
            <div className="sub-title text-animation">
              <div className="section-title text-animation custom-color">
                <animated.h2 style={titleProps}>
                  Our <span>Services</span>
                </animated.h2>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8}>
            <div className="section-title text-animation">
              <animated.h2 style={titleProps}>
                <h2>
                  Tailored Solutions,<br />
                  <span>Seamless Experiences</span>
                </h2>
              </animated.h2>
            </div>
          </Col>
        </Row>
        <Row className="g-lg-4 gy-5">
          <Col lg={4}>
            <ul className="solution-img-wrapper text-animation">
              {services.length > 0 &&
                services.map((service, index) => (
                  <li key={index} className={activeIndex === index ? 'active' : ''}>
                    <div className="solution-img serviceImg">
                      <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${service.ServiceImage}`} alt={`Service ${index + 1}`} className='services-img'/>
                    </div>
                  </li>
                ))}
            </ul>
          </Col>
          <Col lg={8}>
            <div className="accordion accordion-flush" id="accordionFlushExample">
              {services.length > 0 &&
                services.map((service, index) => (
                  <div className="accordion-item" key={index}>
                    <h3 className="accordion-header">
                      <button
                        className={`accordion-button ${activeIndex === index ? '' : 'collapsed'}`}
                        onClick={() => handleToggle(index)}
                        type="button"
                        aria-expanded={activeIndex === index}
                        aria-controls={`flush-collapse-${index}`}
                      >
                        {`${index + 1}. ${service.Title}`}
                        <span>
                          <svg xmlns="http://www.w3.org/2000/svg" width={10} height={10} viewBox="0 0 10 10">
                            <path fillRule="evenodd" clipRule="evenodd" d="M5.42387 0C5.57582 0 5.72154 0.0585322 5.82898 0.16272C5.93642 0.266908 5.99679 0.408218 5.99679 0.555562V8.10343L9.02923 5.16284C9.13729 5.06164 9.28201 5.00564 9.43222 5.00691C9.58244 5.00817 9.72614 5.0666 9.83236 5.16961C9.93858 5.27261 9.99884 5.41195 10.0001 5.55762C10.0014 5.70329 9.9437 5.84362 9.83934 5.9484L5.82892 9.83734C5.72148 9.94149 5.57579 10 5.42387 10C5.27195 10 5.12625 9.94149 5.01882 9.83734L1.0084 5.9484C0.953682 5.89716 0.910035 5.83585 0.880009 5.76807C0.849983 5.70029 0.834179 5.62739 0.833518 5.55362C0.832857 5.47986 0.847352 5.4067 0.876159 5.33842C0.904966 5.27015 0.947507 5.20812 1.0013 5.15595C1.05509 5.10379 1.11906 5.06254 1.18947 5.0346C1.25988 5.00667 1.33532 4.99261 1.41139 4.99325C1.48746 4.99389 1.56264 5.00922 1.63254 5.03834C1.70244 5.06745 1.76566 5.10978 1.8185 5.16284L4.85095 8.10343V0.555562C4.85095 0.408218 4.91131 0.266908 5.01876 0.16272C5.1262 0.0585322 5.27192 0 5.42387 0Z" />
                          </svg>
                        </span>
                      </button>
                    </h3>
                    <div
                      id={`flush-collapse-${index}`}
                      className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
                      aria-labelledby={`flush-heading-${index}`}
                      data-bs-parent="#accordionFlushExample"
                    >
                     <div className="accordion-body">
                        <div dangerouslySetInnerHTML={{ __html: service.Description }} />
                        <Link className="explore-btn" to={`/services/${service.Title}`}>
                          Explore More
                          <svg xmlns="http://www.w3.org/2000/svg" width={12} height={12} viewBox="0 0 12 12">
                            <path d="M10.0035 3.40804L1.41153 12L0 10.5885L8.59097 1.99651H1.01922V0H12V10.9808H10.0035V3.40804Z" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Services;
