import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import ProjectImg from "../assets/Img/projectImg.jpg"
import axios from 'axios'
import Breadcrumb from '../Component/BradeCrum'
import HeaderMobile from '../Component/MobileHader'

const Project = () => {
   const [data,setData]=useState([])

   useEffect(()=>{

    const fetchData = () => {
      axios.get(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/all/project`)
      .then((res)=>{
        console.log(res.data.data)
        setData(res.data.data)
      })
      .catch((error)=>{
        console.error(error)
      })
    }
    fetchData()
    window.scrollTo(0, 0);
   },[])

  return (
    <React.Fragment>
      <HeaderMobile/>
        <Breadcrumb heading={"Projects"} bTitle={"Projects"}/>
        <Container>
            <Row className='galleryRow '>
            <div className="sub-title text-animation">
                <h6>
                  Projects
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={50}
                    height={6}
                    viewBox="0 0 50 6"
                  >
                    <path d="M50 3L45 0.113249V5.88675L50 3ZM0 3.5H45.5V2.5H0V3.5Z" />
                  </svg>
                </h6>
             
              </div>
              {data && data.map((item)=>(
                <React.Fragment key={item._id}>
                 <Col lg={3}>
                <div className='projectTitle'>
                  {item.Title}
                </div>
                </Col>
                <Col lg={9}>
                <Card className='projectCard'>
                  
               <p dangerouslySetInnerHTML={{__html:item.Description}}/>
                </Card>
                </Col>
                </React.Fragment>
               
              ))}
                
            </Row>
        </Container>
    </React.Fragment>
  )
}

export default Project