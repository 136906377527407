import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Card, Container, Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required')
});

const Login = () => {
  const formik = useFormik({
    initialValues: { email: '', password: '' },
    validationSchema: loginSchema,
    onSubmit: (values, { setSubmitting }) => {
      setTimeout(() => {
        alert(JSON.stringify(values, null, 2));
        setSubmitting(false);
      }, 400);
    }
  });

  return (
    <React.Fragment>
      <Container >
        <div className='loginMainDiv'>
        <Card className='loginCard' >
          <Card.Body>
            <h1 className="text-center">Login</h1>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Group as={Row} controlId="formEmail">
                <Form.Label column sm={3}>
                  Email:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email && formik.touched.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPassword" className="mt-3">
                <Form.Label column sm={3}>
                  Password:
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isInvalid={!!formik.errors.password && formik.touched.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <div className='registerLink'>Don't Have an account?<span><Link to="/register">Register</Link></span> </div> 
              <Form.Group as={Row} className="mt-4">
                <Col className="text-center">
                  <button className='loginBtn' type="submit" >
                  Login
                  </button>
                </Col>
              </Form.Group>
            
            </Form>
          </Card.Body>
        </Card>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default Login;
