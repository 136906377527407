import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { useParams } from 'react-router-dom';
import axios from 'axios'
import no_img from "../assets/Img/no-image.jpg";

const ImageWithFallback = ({ src }) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(()=>{
    console.log("okokokokokokokoko",imgSrc)
  },[])

  const handleError = () => {
    setImgSrc(no_img);
  };

  return (
    <img 
      src={imgSrc} 
      alt='img' 
      onError={handleError}
    />
  );
};


const Product = ({setHeading}) => {
  const { productId } = useParams();
  console.log('Category ID:', productId); // Debug log
  const [productData,setProductData]=useState([])
  const [shortDescription, setShortDescription] = useState("")
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryRes = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/categories/${productId}`
        );
        setHeading(categoryRes.data.categoryName)
        // setshortdes(categoryRes.data.shortDescription)
        if(categoryRes.data.shortDescription)
        {
          console.log(categoryRes.data.shortDescription)
          setShortDescription(categoryRes.data.shortDescription)
          // setshortdes(categoryRes.data.shortDescription)
        }
        const res = await axios.get(
          `${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/get/sub-category/${productId}`
        );
        setProductData(res.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [productId]);

  

  return (
    <React.Fragment>
      <div className="container-fluid">
  <Row className=" g-xl-4 gy-5 ">
    <Col xxl={12} className=" order-xl-2 order-1">
    {shortDescription!="" ?
     <Col lg={12} sm={12} className=" mt-4 mb-4 col-sm-6">
     <div className="h-100 product-card" style={{minHeight:"0px", maxHeight:'100%'}}>
        
     <p className="text-center p-4">
                {React.createElement('div', { dangerouslySetInnerHTML: { __html:  shortDescription} })}
                </p>
     </div>
   </Col> : null}
      <Row className=" gy-5 mb-70 product-page">
        {productData && productData.filter((item)=>item.IsActive===true).map((data)=>
        <Col lg={3} sm={6} className="col-sm-6" key={data._id}>
          <div className="product-card">
            <div className="product-card-img">
              <Link  to={`/productCategory/${data._id}`}>
                {/* <img src={`${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.productImage}`} /> */}
                <ImageWithFallback 
          src={data.productImage ? `${process.env.REACT_APP_GRIPHOLD_API_URL}/${data.productImage}` : no_img} 
        />
              </Link>
            </div>
            <div className="product-card-content">
              <h6 className="mb-0"><Link  to={`/productCategory/${data._id}`}>{data.productSubCategory}</Link></h6>
            </div>
          </div>
        </Col>
    )}
      </Row>
    </Col>
  </Row>
</div>

    </React.Fragment>
  )
}

export default Product