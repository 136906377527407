import React, { useState } from 'react'
import Breadcrumb from '../Component/BradeCrum'
import Product from '../Component/Product'
import HeaderMobile from '../Component/MobileHader'




const ProductListing = () => {
  const [heading, setHeading] = useState("")

  return (
    <React.Fragment>
      <HeaderMobile/>
     <Breadcrumb heading={heading} midTitle="All Product" bTitle={heading} />
     <Product setHeading={setHeading} />
    </React.Fragment>
  )
}

export default ProductListing