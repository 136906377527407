import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { FiRefreshCcw } from "react-icons/fi";
import axios from 'axios';
const phoneRegExp = /^\+?(\d[\d-. ]+)?(\([\d-. ]+\))?[\d-. ]+\d$/;

const DownloadModal = ({ file }) => {
  const loginSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    contactNumber:  Yup.string()
    .required("Contact Number is required")
    .matches(phoneRegExp, "Contact Number is not valid")
    .test(
      'is-valid-phone-number',
      'Contact Number must be exactly 10 digits',
      (value) => {
        return value && value.length === 10;
      }
    ),
    email: Yup.string().email("Invalid email").required("Email is required"),
    city: Yup.string().required("City is required"),
    captcha: Yup.string().required("Verification Code is required"),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [show, setShow] = useState(false);
  const [generatedCode, setGeneratedCode] = useState("");
  const [enteredCode, setEnteredCode] = useState("");

  const generateRandomCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  useEffect(() => {
    setGeneratedCode(generateRandomCode());
  }, [show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsSubmitting(true);
    if (values.captcha !== generatedCode) {
      alert("Invalid Verification Code");
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_GRIPHOLD_API_URL}/api/auth/create/CatalogueInquiry`, values);
      if (response.status === 200) {
        handleDownload(file);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
      setShow(false);
    }
  };

  const regenerateCode = () => {
    setGeneratedCode(generateRandomCode());
    setEnteredCode(""); 
  };

  const handleDownload = async (uurl) => {
    const url = `${process.env.REACT_APP_GRIPHOLD_API_URL}/${uurl}`;
    let dnFileName = uurl.split("/");
    dnFileName = dnFileName[dnFileName.length - 1];

    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = `${dnFileName}.pdf`;
      anchor.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <React.Fragment>
      <Link onClick={handleShow} to="#">Download</Link>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Please fill the form to download</Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          <Formik
            initialValues={{
              name: "",
              representativeName: "",
              contactNumber: "",
              email: "",
              city: "",
              captcha: "",
              generatedCode: generatedCode
            }}
            validationSchema={loginSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <div>
                  <label className='downloadLabel'>
                    Name:
                    <Field className="downloadInput" placeholder="Name*" type="text" name="name" />
                    <ErrorMessage name="name" component="div" />
                  </label>
                </div>
                {/* <div>
                  <label className='downloadLabel'>
                    Representative's Name:
                    <Field className="downloadInput" placeholder="Representative's Name*" type="text" name="representativeName" />
                    <ErrorMessage name="representativeName" component="div" />
                  </label>
                </div> */}
                <div>
                  <label className='downloadLabel'>
                    Contact Number:
                    <Field className="downloadInput" placeholder="Contact Number*" type="text" name="contactNumber" />
                    <ErrorMessage name="contactNumber" component="div" />
                  </label>
                </div>
                <div>
                  <label className='downloadLabel'>
                    Email Id:
                    <Field className="downloadInput" placeholder="Email Id*" type="email" name="email" />
                    <ErrorMessage name="email" component="div" />
                  </label>
                </div>
                <div>
                  <label className='downloadLabel'>
                    City:
                    <Field className="downloadInput" placeholder="City*" type="text" name="city" />
                    <ErrorMessage name="city" component="div" />
                  </label>
                </div>
                <div className='captchaDiv'>
                  <div>
                    <label className='downloadLabel'>
                      Verification Code:
                      <input
                        className="downloadInput verificationCode"
                        type="text"
                        readOnly
                        value={generatedCode}
                      />
                    </label>
                  </div>
                  <div>
                    <FiRefreshCcw className='regenerateBtn' type="button" onClick={regenerateCode} />
                  </div>
                </div>
                <div>
                  <label className='downloadLabel'>
                    Enter Verification Code:
                    <Field
                      className="downloadInput"
                      type="text"
                      placeholder="Enter verification code"
                      name="captcha"
                      value={enteredCode}
                      onChange={(e) => {
                        setEnteredCode(e.target.value);
                        setFieldValue('captcha', e.target.value);
                      }}
                    />
                    <ErrorMessage name="captcha" component="div" />
                  </label>
                </div>
                <div className='captchaDiv'>
                  <div>
                    <button className='submitbtn' type="submit" disabled={isSubmitting || enteredCode !== generatedCode}>
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default DownloadModal;
